import { createSelector } from 'reselect'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { AppState } from '@store/index'
import IAppState from '@store/states'

export const getTableData = (state: AppState) => {
    if (state.walletUserAssetsList.isLoading) {
        return { Rows: [], Total: 0, loading: true }
    }
    const { items: Rows } = state.walletUserAssetsList
    return { Rows, Total: Rows.length, loading: false }
}

export const selectWalletDFAList = (state: IAppState) => state.walletUserAssetsList.items
export const selectActive = createSelector(
    selectWalletDFAList,
    dfaDetailsSelector.selectDfaDetails,
    (walletList, dfa) => {
        return walletList.find((active) => active.asset_id === dfa.id)
    },
)
