import { StrictEffect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'
import IAppState from '@store/states'
import { TableOrder } from '@store/types'

import api from '@services/api'

import { getWalletLocksData, setWalletLock, updateWalletLock } from './index'
import { WalletLimit } from './types'

function* handle({ payload }: ReturnType<typeof getWalletLocksData>) {
    try {
        yield* put(
            updateWalletLock({
                isLoading: true,
                error: '',
            }),
        )
        const userId: string = yield* select(authSelector.selectUserUid)
        const orderAndLimit: {
            order?: TableOrder
            limit?: number | string
        } = yield* select((state: IAppState) => state.walletLocks)

        const queryParams = { ...orderAndLimit, ...payload }

        const params = {
            limit: queryParams.limit,
            cursor: queryParams.cursor,
            order:
                queryParams.order && `${queryParams.order?.direct}(${queryParams.order?.column})`,
            process_name: queryParams.process_name,
            executor: queryParams.executor,
        }

        const { error, data } = yield api.lib.getWalletLocksService<WalletLimit>(
            {
                ...params,
            },
            userId,
        )
        if (error) {
            throw error
        }

        yield* put(setWalletLock({ ...queryParams, ...data }))
        yield* put(updateWalletLock({ isLoading: false }))
    } catch (error: ReturnType<Error>) {
        // eslint-disable-next-line no-console
        console.warn(error)
        yield* put(
            updateWalletLock({
                items: [],
                paging: undefined,
                isLoading: false,
                error: error.message,
            }),
        )
    }
}

export function* watchWalletLocks(): Generator<StrictEffect> {
    yield* takeLatest(getWalletLocksData.type, handle)
}
