import { closeMessage, openMessage, openNotification } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'
import { signData } from '@store/helper'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import api from '@services/api'

import { walletEncumbranceSlice } from './index'
import { clearDataTransactionDetails, setIsLoadingTransactionDetails, setItemsTransactionDetails } from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'

function* getItems({ payload }: ReturnType<typeof walletEncumbranceSlice.actions.getItems>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const userId = yield* select(authSelector.selectUserUid)
        if (userId) {
            const { error, data } = yield* call(api.lib.getWalletEncumbranceByUser, userId, {
                ...payload,
                order: 'asc(id)' as const,
            })

            if (error || data?.error) {
                throw error || data?.error
            }

            yield* put(
                walletEncumbranceSlice.actions.setData({
                    items: data?.items || [],
                    paging: data?.paging,
                }),
            )
        }
    } catch (error: ReturnType<Error>) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка обременений',
        })
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

function* getItem({ payload }: ReturnType<typeof walletEncumbranceSlice.actions.getItem>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getWalletEncumbranceById, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* put(walletEncumbranceSlice.actions.setItem(data.item))
        }
    } catch (error: ReturnType<Error>) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения обременения',
        })
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

function* createEncumbrancePrefly({
    payload,
}: ReturnType<typeof walletEncumbranceSlice.actions.createEncumbrance>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const userId = yield* select(authSelector.selectUserUid)
        const createData = yield* select(encumbranceSelectors.selectCreateData)
        if (userId && createData) {
            const { error: pledgeeUuidError, data: pledgeUuidData } = yield* call(
                api.lib.getUidByInn,
                { client_type: createData.pledgee_type, inn: createData.pledgee_inn },
            )
            if (pledgeeUuidError || pledgeUuidData?.error) {
                throw pledgeeUuidError || pledgeUuidData?.error
            }
            if (pledgeUuidData?.item && createData.asset_id) {
                const { error, data } = yield* call(api.lib.setWalletEncumbranceByUserV2, {
                    asset_id: createData.asset_id,
                    depositor_uuid: userId,
                    pledgee_uuid: pledgeUuidData?.item,
                    amount_dfa: Number(createData.amount_dfa),
                    set_reason: createData.set_reason,
                    set_attachment_uuid: createData.set_attachment_uuid?.map(({ uid }) => uid),
                    skid: payload,
                })

                if (error || data?.error) {
                    throw error || data?.error
                }
                
                yield * put(setItemsTransactionDetails(data));
            }
        } 
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* createEncumbrance({
    payload,
}: ReturnType<typeof walletEncumbranceSlice.actions.createEncumbrance>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const createData = yield* select(encumbranceSelectors.selectCreateData)

        const data = yield* select(transactionDetailsSelectors.selectItems)

            if (data?.item) {
                yield* signData(data?.item?.status, payload, data)
                yield* put(
                    walletEncumbranceSlice.actions.setCreateItem({
                        ...createData,
                        uuid: data.item.params.encumbrance,
                    }),
                )
                openNotification({
                    type: 'success',
                    message: 'Запущен процесс наложения обременения',
                })
            }
        yield* put(clearDataTransactionDetails())


    } catch (error: ReturnType<Error>) {
        openNotification({
            type: 'error',
            message: 'Операция отклонена',
        })
        yield* put(clearDataTransactionDetails())
        yield* put(walletEncumbranceSlice.actions.setError(error))
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

function* removeEncumbrancePrefly({
    payload,
}: ReturnType<typeof walletEncumbranceSlice.actions.removeEncumbrance>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const userId = yield* select(authSelector.selectUserUid)

        if (userId) {
            const { error, data } = yield* call(api.lib.removeWalletEncumbranceByUserV2, payload)

            if (error || data?.error) {
                throw error || data?.error
            }
            yield * put(setItemsTransactionDetails(data));
        }
      
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* removeEncumbrance({
    payload,
}: ReturnType<typeof walletEncumbranceSlice.actions.removeEncumbrance>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
            const data = yield* select(transactionDetailsSelectors.selectItems)

            if (data?.item) {
                yield* signData(data?.item?.status, payload, data)
                openNotification({
                    type: 'success',
                    message: 'Запрос на снятие обременения успешно отправлен',
                })
            }

            yield* put(walletEncumbranceSlice.actions.setError(null))
    } catch (error: ReturnType<Error>) {
        openMessage({
            type: 'error',
            message: 'Операция отклонена',
        })

        yield* put(walletEncumbranceSlice.actions.setError(error))
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

export function* watchEncumbrance(): Generator<StrictEffect> {
    yield* takeLatest(walletEncumbranceSlice.actions.getItems.type, getItems)
    yield* takeLatest(walletEncumbranceSlice.actions.getItem.type, getItem)
    yield* takeLatest(walletEncumbranceSlice.actions.createEncumbrance.type, createEncumbrance)
    yield* takeLatest(walletEncumbranceSlice.actions.createEncumbrancePrefly.type, createEncumbrancePrefly)
    yield* takeLatest(walletEncumbranceSlice.actions.removeEncumbrance.type, removeEncumbrance)
    yield* takeLatest(walletEncumbranceSlice.actions.removeEncumbrancePrefly.type, removeEncumbrancePrefly)
}
