import { frontUrl } from '@constants/frontUrl'
import {
    IBusinessmanProfile,
    IBusinessmanProfilePublic,
    IIndividualProfile,
    IIndividualProfilePublic,
    ILegalEntityProfile,
    ILegalEntityProfilePublic,
    ProfileRequestType,
    ProfileType,
} from '@dltru/dfa-models'

export const getQuestionnaireUrlByProfileRequestType = {
    [ProfileRequestType.INDIVIDUAL]: frontUrl.questionnaireIndividual,
    [ProfileRequestType.LEGAL_ENTITY]: frontUrl.questionnaireLegalEntity,
    [ProfileRequestType.BUSINESSMAN]: frontUrl.questionnaireBusinessman,
}

export const getQuestionnaireUrlByProfileType = (profileTypes: ProfileType[]) => {
    if (profileTypes.includes(ProfileType.LEGL)) {
        return `/questionnaire/${frontUrl.questionnaireLegalEntity}`
    }
    if (profileTypes.includes(ProfileType.BUSN)) {
        return `/questionnaire/${frontUrl.questionnaireBusinessman}`
    }
    return `/questionnaire/${frontUrl.questionnaireIndividual}`
}

export const getUserFullNameByType = (
    profileType: ProfileType | undefined,
    profile:
        | IIndividualProfile
        | IBusinessmanProfile
        | ILegalEntityProfile
        | IIndividualProfilePublic
        | IBusinessmanProfilePublic
        | ILegalEntityProfilePublic,
) => {
    switch (profileType) {
        case ProfileType.PRSN: {
            const fioData =
                'person' in profile ? profile.person : (profile as IIndividualProfilePublic)
            return `${fioData.last_name} ${fioData.first_name} ${fioData.second_name}`
        }
        case ProfileType.BUSN: {
            const fioData =
                'person' in profile ? profile.person : (profile as IBusinessmanProfilePublic)
            return `ИП ${fioData.last_name} ${fioData.first_name} ${fioData.second_name}`
        }
        case ProfileType.LEGL: {
            return `${(profile as ILegalEntityProfile).title}`
        }
        default:
            return null
    }
}

export const getUserInnByType = (
    profileType: ProfileType | undefined,
    profile:
        | IIndividualProfile
        | IBusinessmanProfile
        | ILegalEntityProfile
        | IIndividualProfilePublic
        | IBusinessmanProfilePublic
        | ILegalEntityProfilePublic,
) => {
    switch (profileType) {
        case ProfileType.PRSN: {
            const percData =
                'person' in profile ? profile.person : (profile as IIndividualProfilePublic)
            return percData.inn
        }
        case ProfileType.BUSN: {
            const percData =
                'person' in profile ? profile.person : (profile as IBusinessmanProfilePublic)
            return percData.inn
        }
        case ProfileType.LEGL: {
            return (profile as ILegalEntityProfile).inn
        }
        default:
            return null
    }
}
